module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#005d1e","theme_color":"#005d1e","display":"minimal-ui","icon":"src/images/football.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c58a414a1a647ec4c7c940a524883454"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
